<script setup lang="ts">
import { anchorTagFormat } from '@energysage/storyblok-shared/utils/filters';

interface Props {
    blok: ArticleTableOfContentsBlokInterface;
    h2List: ArticleH2BlokInterface[];
    stickyActive: boolean;
}
defineProps<Props>();

const activeHash = ref('');

const observer: Ref<null | IntersectionObserver> = ref(null);
const h2Pointer = ref(-1);
onMounted(() => {
    // tightly coupled to implementation of ArticleH2 :(
    const h2s = Array.from(document.querySelectorAll('.ArticleH2 h2 a'));

    const onElementObserved = (entries: IntersectionObserverEntry[]) => {
        const entry = entries.reduce((previous, current) => {
            return current.time > previous.time ? current : previous;
        });
        // @ts-expect-error
        const hash = entry.target.hash;
        // @ts-expect-error
        const h2Index = h2s.findIndex((value) => value.hash === hash);
        if (entry.isIntersecting) {
            h2Pointer.value = h2Index;
        } else if (entry.boundingClientRect.top > 0) {
            // Heading is below the viewport, so we set the current active heading to the one above it
            h2Pointer.value = h2Index - 1;
        } else {
            // Heading is above the viewport, so this is a noop
            return;
        }

        if (h2Pointer.value >= 0) {
            const parentH2 = h2s[h2Pointer.value];
            // @ts-expect-error
            activeHash.value = parentH2.hash;
        } else {
            activeHash.value = '';
        }
    };
    observer.value = new IntersectionObserver(onElementObserved, {
        threshold: 1,
    });
    h2s.forEach((h2) => {
        observer.value?.observe(h2);
    });
});

const route = useRoute();
watch(route, (newRoute) => {
    if (newRoute.hash) {
        activeHash.value = newRoute.hash;
    }
});

const getHash = (section: ArticleH2BlokInterface) => `#${anchorTagFormat(section.anchorTag || section.text)}`;
</script>

<template>
    <nav class="ArticleToc">
        <es-collapse
            id="collapse-article-toc"
            :visible="true"
            :border="false"
            class="d-lg-block top-divider pt-100">
            <template #title>
                <h2 class="h4 font-size-lg-100 font-weight-bold mb-0">{{ blok.header }}</h2>
            </template>
            <ul
                id="nav-table-of-contents"
                class="nav flex-column pl-150 mb-100">
                <li
                    v-for="section in h2List"
                    :key="section._uid"
                    class="toc-nav-item text-cyan">
                    <a
                        :href="getHash(section)"
                        class="nav-link"
                        :class="{ active: getHash(section) === activeHash }"
                        target="_self">
                        {{ section.text }}
                    </a>
                </li>
            </ul>
        </es-collapse>
        <div class="bottom-divider d-none d-lg-block" />
    </nav>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';

/* stylelint-disable-next-line selector-max-id */
#nav-table-of-contents {
    z-index: 800 !important; // Make sure the table of contents doesn't appear above elements of the sticky CTA
}

.ArticleToc {
    .top-divider {
        border-top: 2px solid variables.$gray-100;
        @include breakpoints.media-breakpoint-up(lg) {
            border-top-width: 3px;
        }
    }

    .bottom-divider {
        border-bottom: 2px solid variables.$gray-100;
        @include breakpoints.media-breakpoint-up(lg) {
            border-bottom-width: 3px;
        }
    }

    .toc-nav-item {
        list-style-type: disc;

        .nav-link {
            padding-left: 0;
            padding-top: 0;

            &.active {
                color: variables.$black;
            }
        }
    }
}
</style>
